import { FullStory } from '@fullstory/browser';
import { isNonEmptyRecord } from '~/utilities/type-guards';
import type { FullStoryAnonUserProperties } from './types';

export const setLiveFullStoryProperties = (
  properties: Partial<FullStoryAnonUserProperties>,
): void => {
  if (isNonEmptyRecord(properties)) {
    FullStory('setProperties', {
      type: 'user',
      properties,
    });
  }
};
