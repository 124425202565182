import { FullStory } from '@fullstory/browser';
import { initializeFullstoryClient } from './initializeFullstoryClient';
import { setUser } from './setUser';

initializeFullstoryClient();

setUser()
  .then((value) => {
    if (value) {
      return undefined;
    }

    /* eslint-disable no-console */
    console.log('user ineligible for fullstory');
    return undefined;
  })

  .catch((error: unknown) => {
    /* eslint-disable no-console */
    console.log("Couldn't initialize fullstory");
    console.log(error);
    FullStory('shutdown');
    /* eslint-enable no-console */
  });
